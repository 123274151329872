<template>
  <div class="k-pager-wrap k-grid-pager k-widget custom-pager">
    <a
      href="#"
      class="k-link k-pager-nav k-pager-first k-state-disabled"
      title="Go to the first page"
      ><span
        class="k-icon k-i-seek-w"
        aria-label="Go to the first page"
      ></span></a
    ><a
      href="#"
      class="k-link k-pager-nav k-state-disabled"
      title="Go to the previous page"
      ><span
        class="k-icon k-i-arrow-w"
        aria-label="Go to the previous page"
      ></span></a
    ><span class="k-pager-input k-label">
      <!-- 頁數值 -->
      <input class="k-textbox" :value="pageVlaue" /> </span
    ><a href="#" class="k-link k-pager-nav" title="Go to the next page"
      ><span
        class="k-icon k-i-arrow-e"
        aria-label="Go to the next page"
      ></span></a
    ><a
      href="#"
      class="k-link k-pager-nav k-pager-last"
      title="Go to the last page"
      ><span class="k-icon k-i-seek-e" aria-label="Go to the last page"></span
    ></a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pageVlaue: Number
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
