<template>
  <div id="page-ip-conflict">
    <h2 class="ip-conflict-title">IP 衝突開始</h2>
    <div class="d-flex flex-row">
      <!-- 左側：樹狀圖 -->
      <div class="tree-nav-container align-items-stretch">
        <h3 class="tree-title">選取查詢範圍</h3>
        <div class="treeview-wrapper">
          <TreeView
            ref="treeViewRef"
            :data-source="localDataSource"
            :data-text-field="['name']"
            :template="template"
            :checkboxes="true"
            :checkboxes-check-children="true"
          ></TreeView>
        </div>
      </div>
      <div class="list-container">
        <FilterWrapper>
          <FilterTextInput
            :placeholder="'查詢 IP / MAC'"
            v-model.trim="textInput"
          />
        </FilterWrapper>
        <GridPageInfo :skip="0" :take="4" :total="1000" />
        <div class="conflict-list">
          <!-- 一組 IP 衝突 -->
          <div class="box-panel">
            <div class="box-panel-title-row">
              <h5 class="box-panel-title">192.168.187.111</h5>
              <div class="box-panel-btn-group">
                <BaseButton
                  class="btn-primary-600 mx-2"
                  style="width: 129px"
                  @click="toggleSetStaticIP"
                  >設定固定 IP</BaseButton
                >
                <BaseButton
                  class="btn-primary-600 mx-1"
                  style="width: 129px"
                  @click="toggleSetGLBP"
                  >設定 GLBP</BaseButton
                >
              </div>
            </div>
            <div class="box-panel-content">
              <div
                class="main-ip-column align-items-stretch flex-grow-1 justify-content-start"
              >
                <MacConflictCard />
              </div>
              <div
                class="conflict-ip-column align-items-stretch flex-grow-1 justify-content-start"
              >
                <MacConflictCard :showConflict="true" />
              </div>
            </div>
          </div>
          <div class="box-panel">
            <div class="box-panel-title-row">
              <h5 class="box-panel-title">192.168.187.111</h5>
              <div class="box-panel-btn-group">
                <BaseButton
                  class="btn-primary-600 mx-2"
                  style="width: 129px"
                  @click="toggleSetStaticIP"
                  >設定固定 IP</BaseButton
                >
                <BaseButton
                  class="btn-primary-600 mx-1"
                  style="width: 129px"
                  @click="toggleSetGLBP"
                  >設定 GLBP</BaseButton
                >
              </div>
            </div>
            <div class="box-panel-content">
              <div class="main-ip-column flex-grow-1 justify-content-start">
                <MacConflictCard />
              </div>
              <div class="conflict-ip-column flex-grow-1 justify-content-start">
                <MacConflictCard :showConflict="true" />
                <MacConflictCard :showConflict="true" />
                <MacConflictCard :showConflict="true" />
                <MacConflictCard :showConflict="true" />
                <MacConflictCard :showConflict="true" />
              </div>
            </div>
          </div>
          <KendoPager class="mx-auto w-100" :pageVlaue="1" />
          <!-- 無資料時顯示，請自行加入 v-if -->
          <div class="no-data">目前沒有資料</div>
        </div>
      </div>
    </div>
    <!-- 隱藏：Dialog 容器 -->
    <div class="dialog-container">
      <Dialog
        v-if="showSetGLBP"
        :title="'設定GLBP'"
        :width="930"
        :height="475"
        @close="toggleSetGLBP"
      >
        <SetGLBPForm @close="toggleSetGLBP" />
      </Dialog>
      <Dialog
        v-if="showSetStaticIP"
        :title="'設定固定IP'"
        :width="930"
        :height="420"
        @close="toggleSetStaticIP"
      >
        <SetStaticIPForm @close="toggleSetStaticIP" />
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, markRaw } from 'vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import BaseButton from '@/components/Button/BaseButton.vue'
import MacConflictCard from '@/components/Card/MacConflictCard.vue'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import SetGLBPForm from '@/components/Form/IpConflictPage/SetGLBPForm.vue'
import SetStaticIPForm from '@/components/Form/IpConflictPage/SetStaticIPForm.vue'
import KendoPager from '@/components/Kendo/KendoPager.vue'

import { treeViewData, treeViewSchema } from '@/mock/data/host'

export default defineComponent({
  components: {
    TreeView,
    FilterWrapper,
    FilterTextInput,
    GridPageInfo,
    BaseButton,
    MacConflictCard,
    KendoPager,
    Dialog,
    SetGLBPForm,
    SetStaticIPForm
  },
  setup() {
    // FIXME：box-panel 尚未元件化

    // TreeView 相關
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    // 篩選相關，文字輸入
    const textInput = ref('')

    const isTreeViewOpen = ref(true)

    // Dialog 相關
    const { show: showSetGLBP, toggle: toggleSetGLBP } = useToggle()
    const { show: showSetStaticIP, toggle: toggleSetStaticIP } = useToggle()

    return {
      template,
      localDataSource,
      isTreeViewOpen,
      textInput,
      showSetGLBP,
      toggleSetGLBP,
      showSetStaticIP,
      toggleSetStaticIP
    }
  }
})
</script>

<style scoped></style>
