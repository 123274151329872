<template>
  <div class="form-wrapper">
    <form>
      <div class="single-feild-group" style="padding-left: 120px;">
        <label class="feild-name">IP</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput
            :type="'text'"
            style="width: 280px;"
            :value="'192.168.67.232'"
            :readonly="true"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group mb-5" style="padding-left: 120px;">
        <label class="feild-name">MAC</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <DropDownList
            style="width: 280px;"
            :data-source="[{ name: '00195BF4EF48', value: 0 }]"
            :data-text-field="'name'"
            :data-value-field="'value'"
          />
        </FeildInputGroup>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成設定
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList
  },
  setup() {
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      submitForm
    }
  }
})
</script>

<style scoped></style>
