
import { defineComponent } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList
  },
  setup() {
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      submitForm
    }
  }
})
