
import { defineComponent, ref, markRaw } from 'vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import BaseButton from '@/components/Button/BaseButton.vue'
import MacConflictCard from '@/components/Card/MacConflictCard.vue'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import SetGLBPForm from '@/components/Form/IpConflictPage/SetGLBPForm.vue'
import SetStaticIPForm from '@/components/Form/IpConflictPage/SetStaticIPForm.vue'
import KendoPager from '@/components/Kendo/KendoPager.vue'

import { treeViewData, treeViewSchema } from '@/mock/data/host'

export default defineComponent({
  components: {
    TreeView,
    FilterWrapper,
    FilterTextInput,
    GridPageInfo,
    BaseButton,
    MacConflictCard,
    KendoPager,
    Dialog,
    SetGLBPForm,
    SetStaticIPForm
  },
  setup() {
    // FIXME：box-panel 尚未元件化

    // TreeView 相關
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    // 篩選相關，文字輸入
    const textInput = ref('')

    const isTreeViewOpen = ref(true)

    // Dialog 相關
    const { show: showSetGLBP, toggle: toggleSetGLBP } = useToggle()
    const { show: showSetStaticIP, toggle: toggleSetStaticIP } = useToggle()

    return {
      template,
      localDataSource,
      isTreeViewOpen,
      textInput,
      showSetGLBP,
      toggleSetGLBP,
      showSetStaticIP,
      toggleSetStaticIP
    }
  }
})
