<template>
  <div class="mac-card">
    <template v-if="showConflict">
      <img
        class="conflict-img"
        :src="require('@/assets/images/img/conflict@2x.png')"
      />
      <div class="conflict-top-line"></div>
      <div class="conflict-bottom-line"></div>
      <span class="conflict-time"
        ><SvgIcon icon="time" />2020/10/11 14:00:33</span
      >
    </template>
    <div class="card-block">
      <img class="mac-img" :src="require('@/assets/images/img/mac@2x.png')" />
      <div class="info-row">
        <span class="info-name">原使用 MAC</span
        ><span
          class="info-value copy-wrapper"
          v-clipboard="'00195BF4EF48'"
          v-clipboard:success="
            () => this.$toast.show('00195BF4EF48', 'success')
          "
        >
          <KendoTooltip id="tooltip" title="點擊複製" :position="'right'">
            <span>00195BF4EF48</span>
            <SvgIcon icon="copy" class="copy-icon" />
          </KendoTooltip>
        </span>
      </div>
      <div class="info-row">
        <span class="info-name">主機名稱</span
        ><span class="info-value">Davin_PC</span>
      </div>
      <div class="info-row">
        <span class="info-name">Switch ID</span
        ><span class="info-value">C6513-E</span>
      </div>
      <div class="info-row">
        <span class="info-name">Switch Port</span
        ><span class="info-value"
          >C3850-24T for 3F
          <div class="operation-btn">
            <SvgIcon icon="dot-more" />
          </div>
        </span>
      </div>
      <div class="info-row">
        <span class="info-name">網卡廠商</span
        ><span class="info-value">D-Link Corporation</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    showConflict: Boolean
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
